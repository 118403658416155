<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getNotifications } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'

export default {
  setup () {
    const notificationsList = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      created_at: {
        from: null,
        to: null
      },
      confirmed_at: {
        from: null,
        to: null
      }
    })

    onMounted(() => {
      fetchNotifications()
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchNotifications = debounce(() => {
      const params = {
        page: page.value,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        confirmed_at: {
          gte: dateToISO(filter.value.confirmed_at.from),
          lte: dateToISO(filter.value.confirmed_at.to)
        },
        order: {
          created_at: 'desc'
        }
      }

      getNotifications(params).then((data) => {
        notificationsList.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).catch(() => {
        Notify.create({
        })
      })
    }, 1000)

    watch(filter, () => {
      page.value = 1
      fetchNotifications()
    }, { deep: true })

    return {
      page,
      pages,
      total,
      notificationsList,
      fetchNotifications,
      filter
    }
  },
  components: {
    AppPagination,
    Layout
  }
}

</script>

<template>
  <Layout :pagetitle="'Notificações'">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-md-12">
          <div
            class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3"
          >
            <div>
              <ul class="nav nav-pills">
                <div>
                  <router-link
                    :to="{ name: 'create_notification' }"
                    class="btn btn-primary"
                  >
                    <i class="bx bx-plus me-1" /> Gerar Notificações
                  </router-link>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
              <thead>
                  <tr>
                    <th scope="col">
                      Título
                    </th>
                    <th scope="col">
                      Conteúdo
                    </th>
                    <th scope="col">
                      Descrição
                    </th>
                    <th scope="col">
                      Tipo
                    </th>
                    <th scope="col">
                      Criado em
                    </th>
                    <th scope="col">
                      Atualizado em
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="notifications in notificationsList"
                    :key="notifications.id"
                  >
                    <td>{{ notifications.title }}</td>
                    <td>{{ notifications.content }}</td>
                    <td>{{ notifications.short_description }}</td>
                    <td>{{ notifications.type }}</td>
                    <td>{{ notifications.created_at? $d(notifications.created_at, 'info') : '' }}</td>
                    <td>{{ notifications.updated_at? $d(notifications.updated_at, 'info') : '' }}</td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="2"
                @update:modelValue="fetchNotifications"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

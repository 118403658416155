import { axios } from '@/axios'

export const getNotifications = (params = {}) => {
  return axios.get('notifications/admin', { params }).then(({ data }) => data)
}
export const generateNotifications = (params) => {
  return axios.post('notifications/admin/generate', params).then(({ data }) => data)
}
export const getNotificationsTemplate = (params = {}) => {
  return axios.get('/notifications-templates', { params }).then(({ data }) => data)
}
